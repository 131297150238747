export const pubsub = {
    on(name, cb) {
        const subs = allSubs.get(name);
        if (subs)
            subs.add(cb);
        else
            allSubs.set(name, new Set([cb]));
    },
    off(name, cb) {
        allSubs.get(name)?.delete(cb);
    },
    emit(name, ...args) {
        for (const fn of allSubs.get(name) || [])
            fn.apply(null, args);
    },
    after(event) {
        const found = oneTimeEvents.get(event);
        if (found)
            return found.promise;
        const handler = {};
        handler.promise = new Promise(resolve => (handler.resolve = resolve));
        oneTimeEvents.set(event, handler);
        return handler.promise;
    },
    complete(event) {
        const found = oneTimeEvents.get(event);
        if (found) {
            found.resolve?.();
            found.resolve = undefined;
        }
        else
            oneTimeEvents.set(event, { promise: Promise.resolve() });
    },
    past(event) {
        return oneTimeEvents.has(event) && !oneTimeEvents.get(event)?.resolve;
    },
};
const allSubs = new Map();
const oneTimeEvents = new Map();
//export default pubsub;
